import { Link } from "react-router-dom";
function Footer() {
    return (
    <div className="page-wrapper">
    <>
  {/*Site Footer Start*/}
  <footer className="site-footer">
    <div
      className="site-footer__bg"
      style={{
        backgroundImage: "url(assets/images/backgrounds/site-footer-bg.png)"
      }}
    ></div>
    <div className="site-footer__top">
      <div className="container">
        <div className="site-footer__top-inner">
          <div className="row">
            <div
              className="col-lg-4 col-md-12 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="footer-widget__column footer-widget__about">
                <div className="footer-widget__logo">
                  <a href="index.html">
                    <img
                      src="assets/images/resources/logosmall.png"
                      width={300}
                      alt=""
                    />
                  </a>
                </div>
                <p className="footer-widget__about-text">
                  We offer a diverse fleet of luxury vehicles <br /> to meet
                  your transportation needs.
                </p>
                <div className="footer-widget__social">
                  <a href="#">
                    <span className="icon-facebook" />
                  </a>
                  <a href="#">
                    <span className="icon-twitter" />
                  </a>
                  <a href="#">
                    <span className="icon-linkin" />
                  </a>
                  <a href="#">
                    <span className="icon-pinterest" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-12 wow fadeInUp"
              data-wow-delay="200ms"
            >
              
              <div className="footer-widget__column footer-widget__services">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Services</h3>
                </div>
                <ul className="footer-widget__services-list list-unstyled">
                  <li>
                    <Link to="/airporttransfer">
                      <span className="icon-arrow-left" />
                      Airport transfer
                    </Link>
                  </li>
                  <li>
                    <Link to="/chauffeurservices">
                      <span className="icon-arrow-left" />
                      Chauffeur Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/limousineservice">
                      <span className="icon-arrow-left" />
                      Limousine Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/hourlyrateservice">
                      <span className="icon-arrow-left" />
                      Hourly Rate Service
                    </Link>
                  </li>
                  <li>
                    <a href="">
                      <span className="icon-arrow-left" />
                      Event Transportation
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="footer-widget__column footer-widget__useful-link">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Useful Links</h3>
                </div>
                <ul className="footer-widget__services-list list-unstyled">
                  <li>
                    <Link to="/about">
                      <span className="icon-arrow-left" />
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <span className="icon-arrow-left" />
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/ourfleet">
                      <span className="icon-arrow-left" />
                      Our Fleet
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery">
                      <span className="icon-arrow-left" />
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="icon-arrow-left" />
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="footer-widget__column footer-widget__contact">
                <div className="footer-widget__title-box">
                  <h3 className="footer-widget__title">Contact</h3>
                </div>
                <ul className="footer-widget__contact-list list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="icon-envolop" />
                    </div>
                    <a href="mailto:debra.holt@example.com">
                      booking@rizchauffeur.com
                    </a>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-location" />
                    </div>
                    <p>718 Block-B, Business Village, Dubai-UAE</p>
                  </li>
                </ul>
                <form className="footer-widget__contact-form" style={{display:'none'}}>
                  <div className="footer-widget__contact-input-box">
                    <input
                      type="email"
                      placeholder="Your e-mail"
                      name="email"
                    />
                    <button
                      type="submit"
                      className="footer-widget__contact-btn"
                    >
                      <i className="icon-paper-plan" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="site-footer__bottom">
      <div className="container">
        <div className="site-footer__bottom-inner">
          <p className="site-footer__bottom-text">
            © riz 2024 | All Rights Reserved
          </p>
          <ul className="list-unstyled site-footer__bottom-menu">
            <li>
              <a href="/terms">Terms &amp; Condition</a>
            </li>
            <li>
              <a href="/privacypolicy">Privacy Policy</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  {/*Site Footer End*/}
</>

    </div>
);
}
export default Footer;