import React,{useEffect,useState,useRef} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { usePlacesWidget } from "react-google-autocomplete";

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import Validation from './validation';

const libraries = ['places'];


function Header() {


  const [isOpen, setIsOpen] = useState(false);

 
  const inputRef = useState();
  

  const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyApLXk59hUqmQkHwBWa3BRVftYvfuJuTqU',
      libraries
  });

  const handlePlaceChanged = () => { 
    console.log(inputRef)
   /* const [ place ] = inputRef.current.getPlaces();;
    if(place) { 
        console.log(place.formatted_address)
        console.log(place.geometry.location.lat())
        console.log(place.geometry.location.lng())
    } */
}

    useEffect(() => {
        if ($(".sticky-header__content").length) {
          let navContent = document.querySelector(".main-menu").innerHTML;
          let mobileNavContainer = document.querySelector(".sticky-header__content");
          mobileNavContainer.innerHTML = navContent;
        }
    
        if ($(".custom-cursor").length) {
    
          var cursor = document.querySelector('.custom-cursor__cursor');
          var cursorinner = document.querySelector('.custom-cursor__cursor-two');
          var a = document.querySelectorAll('a');
      
          document.addEventListener('mousemove', function (e) {
           
            cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
          });
      
          document.addEventListener('mousemove', function (e) {
            var x = e.clientX;
            var y = e.clientY;
            cursorinner.style.left = x + 'px';
            cursorinner.style.top = y + 'px';
          });
      
          document.addEventListener('mousedown', function () {
            cursor.classList.add('click');
            cursorinner.classList.add('custom-cursor__innerhover')
          });
      
          document.addEventListener('mouseup', function () {
            cursor.classList.remove('click')
            cursorinner.classList.remove('custom-cursor__innerhover')
          });
      
          a.forEach(item => {
            item.addEventListener('mouseover', () => {
              cursor.classList.add('custom-cursor__hover');
            });
            item.addEventListener('mouseleave', () => {
              cursor.classList.remove('custom-cursor__hover');
            });
          })
    
        }
         
        if ($(".mobile-nav__toggler").length) {
          $(".mobile-nav__toggler").on("click", function (e) {
            e.preventDefault();
            $(".mobile-nav__wrapper").toggleClass("expanded");
            $("body").toggleClass("locked");
          });
        }
         
        if ($(".main-menu__list").length && $(".mobile-nav__container").length) {
          let navContent = document.querySelector(".main-menu__list").outerHTML;
          let mobileNavContainer = document.querySelector(".mobile-nav__container");
          mobileNavContainer.innerHTML = navContent;
        }

        if ($(".mobile-nav__container .main-menu__list").length) {
          let dropdownAnchor = $(
            ".mobile-nav__container .main-menu__list .dropdown > a"
          );
          dropdownAnchor.each(function () {
            let self = $(this);
            let toggleBtn = document.createElement("BUTTON");
            toggleBtn.setAttribute("aria-label", "dropdown toggler");
            toggleBtn.innerHTML = "<i class='fa fa-angle-down'></i>";
            self.append(function () {
              return toggleBtn;
            });
            self.find("button").on("click", function (e) {
              e.preventDefault();
              let self = $(this);
              self.toggleClass("expanded");
              self.parent().toggleClass("expanded");
              self.parent().parent().children("ul").slideToggle();
            });
          });
        }
      
       
     },[]);

      const { ref } = usePlacesWidget({
        
        apiKey: 'AIzaSyApLXk59hUqmQkHwBWa3BRVftYvfuJuTqU',
        onPlaceSelected: (place) => {
          console.log(place);
          console.log(place);
        },
        options: {
          types: ["(regions)"],
          componentRestrictions: { country: "en" },
        },
      });
    
      const handleClick = () => {
        setIsOpen(true);
      }

      const handleClose = () => {
        setIsOpen(false);
      }

      const navigate = useNavigate()


  
  const [values, setValues] = useState({
    txtFromAddress:'',
    txtToAddress:'',
    startDateoneway:'',
    startTimeoneway:'',
    oneWayVehicle:''
  })

   const [valuesHour, setValuesHour] = useState({
    pickupaddresshour:'',
    duration:'',
    hourdate:'',
    hourTime:'',
    hourVehicle:''
  })

  const [selected, setSelected] = useState(0);

  const [errors,SetErrors]=useState({})

  const handleInput=(e)=>
  {
    //e.persist();
    const val = e.target.value;
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
    SelectedVeicle(e);
    SetErrors(validateValues(values));
    
    //setSelected(val)
  }

  const SelectedVeicle =(e)=>
  {
    if(e.target.value==="BMW 7 series")
      {
        document.querySelector('#txtOneWayPassengers').value="3 Passengers";
        document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
      }
      else if(e.target.value==="50 Seater Luxury Bus")
      {
        document.querySelector('#txtOneWayPassengers').value="50 Passengers";
        document.querySelector('#txtOneWayBaggage').value="Max. 45 Baggage";
      }
      else if(e.target.value==="35 Seater Luxury Bus")
        {
          document.querySelector('#txtOneWayPassengers').value="35 Passengers";
          document.querySelector('#txtOneWayBaggage').value="Max. 30 Baggage";
        }
        else if(e.target.value==="Audi A6")
          {
            document.querySelector('#txtOneWayPassengers').value="3 Passengers";
            document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
          }
          else if(e.target.value==="Rolls Royce Ghost")
            {
              document.querySelector('#txtOneWayPassengers').value="3 Passengers";
              document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
            }

            else if(e.target.value==="Rolls-Royce Cullinan")
              {
                document.querySelector('#txtOneWayPassengers').value="4 Passengers";
                document.querySelector('#txtOneWayBaggage').value="Max. 3 Baggage";
              }
              else if(e.target.value==="Bentley Flying Spur")
                {
                  document.querySelector('#txtOneWayPassengers').value="3 Passengers";
                  document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
                }
                else if(e.target.value==="Mercedes S Class")
                  {
                    document.querySelector('#txtOneWayPassengers').value="Max. 2 Baggage";
                    document.querySelector('#txtOneWayBaggage').value="3 Passengers";
                   
                  }
                  else if(e.target.value==="Audi A8")
                    {
                      document.querySelector('#txtOneWayPassengers').value="3 Passengers";
                      document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
                    }
                    else if(e.target.value==="Cadillac Escalade")
                      {
                        document.querySelector('#txtOneWayPassengers').value="6 Passengers";
                        document.querySelector('#txtOneWayBaggage').value="Max. 5 Baggage";
                      }
                      else if(e.target.value==="Mercedes V class")
                        {
                          document.querySelector('#txtOneWayPassengers').value="7 Passengers";
                          document.querySelector('#txtOneWayBaggage').value="Max. 5 Baggage";
                        }
                        else if(e.target.value==="Honda Odyssey")
                          {
                            document.querySelector('#txtOneWayPassengers').value="4 Passengers";
                            document.querySelector('#txtOneWayBaggage').value="Max. 5 Baggage";
                          }
                          else if(e.target.value==="Toyota Previa")
                            {
                              document.querySelector('#txtOneWayPassengers').value="4 Passengers";
                              document.querySelector('#txtOneWayBaggage').value="Max. 5 Baggage";
                            }
                            else if(e.target.value==="Toyota Granvia")
                              {
                                document.querySelector('#txtOneWayPassengers').value="4 Passengers";
                                document.querySelector('#txtOneWayBaggage').value="Max. 3 Baggage";
                              }
                              else if(e.target.value==="Lexus ES 350")
                                {
                                  document.querySelector('#txtOneWayPassengers').value="3 Passengers";
                                  document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
                                }

                                else if(e.target.value==="Mercedes E Class")
                                  {
                                    document.querySelector('#txtOneWayPassengers').value="4 Passengers";
                                    document.querySelector('#txtOneWayBaggage').value="Max. 3 Baggage";
                                  }
                                  else if(e.target.value==="BMW 5 Series")
                                    {
                                      document.querySelector('#txtOneWayPassengers').value="3 Passengers";
                                      document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
                                    }
                                    else if(e.target.value==="Mercedes Sprinter")
                                      {
                                        document.querySelector('#txtOneWayPassengers').value="16 Passengers";
                                        document.querySelector('#txtOneWayBaggage').value="Max. 12 Baggage";
                                      }
                                      else if(e.target.value==="Toyota Hiace")
                                        {
                                          document.querySelector('#txtOneWayPassengers').value="11 Passengers";
                                          document.querySelector('#txtOneWayBaggage').value="Max. 7 Baggage";
                                        }
                                        else if(e.target.value==="Toyota Coaster")
                                          {
                                            document.querySelector('#txtOneWayPassengers').value="21 Passengers";
                                            document.querySelector('#txtOneWayBaggage').value="Max. 16 Baggage";
                                          }
                                          else if(e.target.value==="Rolls Royce Phantom")
                                            {
                                              document.querySelector('#txtOneWayPassengers').value="3 Passengers";
                                              document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
                                            }
                                            else if(e.target.value==="Strech Limousine")
                                              {
                                                document.querySelector('#txtOneWayPassengers').value="16 Passengers";
                                                document.querySelector('#txtOneWayBaggage').value="Max. 0 Baggage";
                                              }
                                              else if(e.target.value==="GMC Yukon")
                                                {
                                                  document.querySelector('#txtOneWayPassengers').value="6 Passengers";
                                                  document.querySelector('#txtOneWayBaggage').value="Max. 5 Baggage";
                                                }
                                                else if(e.target.value==="Tesla Model 3")
                                                  {
                                                    document.querySelector('#txtOneWayPassengers').value="3 Passengers";
                                                    document.querySelector('#txtOneWayBaggage').value="Max. 2 Baggage";
                                                  }
                                                  else if(e.target.value==="Tesla Model Y")
                                                    {
                                                      document.querySelector('#txtOneWayPassengers').value="3 Passengers";
                                                      document.querySelector('#txtOneWayBaggage').value="Max. 3 Baggage";
                                                    }
  }


   const handleInputHour=(e)=>
  {
    e.persist();
    setValuesHour(valuesHour => ({ ...valuesHour, [e.target.name]: e.target.value }));
    SelectedVeicle(e);
    SetErrors(validateValuesHour(valuesHour));
  }

  const validateValues = (inputValues) => {
    const errors={};
    if(inputValues.txtFromAddress==="")
      {
          errors.txtFromAddress='This field is required';
      }
      else
      {
        if(inputValues.txtToAddress==="")
          {
            errors.txtToAddress=" This field is required";
          }
          else
          {
            if(inputValues.startDateoneway==="")
              {
                errors.startDateoneway=" This field is required";
              }
              else
              {
                if(inputValues.startTimeoneway==="")
                  {
                    errors.startTimeoneway=" This field is required";
                  }
                  else{
                    if(inputValues.oneWayVehicle==="")
                      {
                        errors.oneWayVehicle=" This field is required";
                      }
                      else
                      {
                        
                        errors.oneWayVehicle="";
                      }
                      errors.startTimeoneway="";
                  }
                  errors.startDateoneway="";
              }
              errors.txtToAddress="";
          }
          
        errors.txtFromAddress="";
      }
     
        return  errors ;
  };  

    const validateValuesHour = (inputValues) => {
    const errors={};
    if(inputValues.pickupaddresshour==="")
      {
          errors.pickupaddresshour='This field is required';
      }
      else if(inputValues.duration==="")
      {
        errors.duration=" This field is required";
      }
      else if(inputValues.hourdate==="")
        {
          errors.hourdate=" This field is required";
        }
        else if(inputValues.hourTime==="")
          {
            errors.hourTime=" This field is required";
          }
          else if(inputValues.hourVehicle==="")
            {
              errors.hourVehicle=" This field is required";
            }
      else{
        errors.pickupaddresshour="";
        errors.duration="";
        errors.hourdate="";
        errors.hourTime="";
        errors.hourVehicle="";
      }
     
        return  errors ;
  };  

  const [datas, setDatas] = useState([]);
  const SubmitQuote=(event)=>{
    SetErrors(validateValues(values));
      if(values.txtFromAddress!==""  && values.txtToAddress !=="" && values.startDateoneway !=="" 
        && values.startTimeoneway !=="" &&  values.oneWayVehicle !=="")
      {
      setIsOpen(false);
      //navigate("/bookrizchauffeur");
      let fromAdd = document.querySelectorAll('#txtFromAddress')[0].value;
      let toAdd = document.querySelectorAll('#txtToAddress')[0].value;
      let startDate = document.querySelectorAll('#startDateoneway')[0].value;
      let startTime = document.querySelectorAll('#startTimeoneway')[0].value;
      let oneWayVehicle = document.querySelector('#oneWayVehicle').value;
      let seats =document.querySelectorAll('#txtOneWayPassengers')[0].value;
      let baggage =document.querySelectorAll('#txtOneWayBaggage')[0].value;
      setDatas([...datas,
        {fromAdd:fromAdd},
        {toAdd:toAdd},
        {startDate:startDate},
        {startTime:startTime},
        {oneWayVehicle:oneWayVehicle},
        {seats:seats},
        {baggage:baggage}
      ])
      console.log("datas",datas)
      navigate("/bookrizchauffeur", {state: {fromAdd: fromAdd,toAdd:toAdd,startDate:startDate,startTime:startTime,
        oneWayVehicle:oneWayVehicle,seats:seats,baggage:baggage
      }});
      }
  }

 
   const SubmitHourQuote=(event)=>{
    SetErrors(validateValuesHour(valuesHour));
      if(valuesHour.pickupaddresshour!==""  && valuesHour.duration !=="" && valuesHour.hourdate !=="" 
        && valuesHour.hourTime !=="" && valuesHour.hourVehicle !=="")
      {
      setIsOpen(false);
      navigate("/bookrizchauffeur");
      }
  }


 
  


    return (
        <>
       
       <header className="main-header">
    <div className="main-header__wrapper">
      <div className="main-header__logo" style={{ width: 350 }}>
        <a
          href="/"
          style={{
            backgroundColor: "black",
            paddingTop: 20,
            paddingLeft: "20PX"
          }}
        >
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginBottom: "-18px" }}
            alt=""
          />
        </a>
        {/*<a href="index.html" style="background-color: black; width: 600px; max-height: 200px;" ><img src="assets/images/resources/logosmall.png" alt=""></a>*/}
        {/*<a href="index.html" style="background-color: black;"><span style="color: goldenrod; font-weight:600; font-family: Verdana, Geneva, Tahoma, sans-serif;">RIZ CHAUFFEUR</span></a>*/}
      </div>
      <div className="main-menu__top" style={{ backgroundColor: "black" }}>
        <div className="main-menu__top-inner">
          <ul className="list-unstyled main-menu__contact-list">
            <li>
              <div className="icon">
                <i className="icon-call" style={{ color: "white" }} />
              </div>
              <div className="text">
                <p>
                  <a href="tel:629555-0129" style={{ color: "goldenrod" }}>
                    +971 563847412
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="icon">
                <i className="icon-envolop" style={{ color: "white" }} />
              </div>
              <div className="text">
                <p>
                  <a
                    href="mailto:info@example.com"
                    style={{ color: "goldenrod" }}
                  >
                    info@rizchauffeur.com
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="icon">
                <i className="icon-location" style={{ color: "white" }} />
              </div>
              <div className="text">
                <p style={{ color: "goldenrod" }}>
                  Office #:718, Block - B, Business Village,Dubai
                </p>
              </div>
            </li>
          </ul>
          <div className="main-menu__top-right">
            <div className="main-menu__social-box">
              <div className="main-menu__social-box-inner">
                
                <div className="main-menu__social">
                  <a href="r">
                    <i className="icon-facebook" />
                  </a>
                  <a href="r">
                    <i className="icon-instagram" />
                  </a>
                  <a href="r">
                    <i className="icon-twitter" />
                  </a>
                  <a href="r">
                    <i className="icon-linkin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <a href="https://wa.me/+971563847412" target="_blank" class="float" title="WhatsApp">
          <img alt="" loading="lazy" decoding="async" data-nimg="1" style={{color:"transparent"}}
src="assets/images/news/whatsAppIcon.png" /></a>
      <nav className="main-menu">
        <div className="main-menu__wrapper">
          <div className="main-menu__wrapper-inner">
            <div className="main-menu__left">
              <div className="main-menu__main-menu-box">
                <a href="/" className="mobile-nav__toggler">
                  <i className="fa fa-bars" />
                </a>
                <ul className="main-menu__list">
                  <li>
                    <a href="/">
                    <li>Home</li>
                </a>
                  </li>
                  {/*<li class="dropdown">
                                      <a href="index-dark.html">Home </a>
                                     <ul>
                                          <li><a href="index.html">Home One</a></li>
                                          <li><a href="index2.html">Home Two</a></li>
                                          <li><a href="index3.html">Home Three</a></li>
                                          <li><a href="index4.html">Home Four</a></li>
                                          <li><a href="index5.html">Home Five</a></li>
                                          <li><a href="index-dark.html">Home Dark</a></li>
                                          <li class="dropdown">
                                              <a href="#">Header Styles</a>
                                              <ul>
                                                  <li><a href="index.html">Header One</a></li>
                                                  <li><a href="index2.html">Header Two</a></li>
                                                  <li><a href="index3.html">Header Three</a></li>
                                                  <li><a href="index4.html">Header Four</a></li>
                                                  <li><a href="index5.html">Header Five</a></li>
                                              </ul>
                                          </li>
                                          <li class="dropdown">
                                              <a href="#">One Page Styles</a>
                                              <ul>
                                                  <li><a href="index-one-page.html">One Page Styles One</a></li>
                                                  <li><a href="index2-one-page.html">One Page Styles Two</a></li>
                                                  <li><a href="index3-one-page.html">One Page Styles Three</a>
                                                  <li><a href="index4-one-page.html">One Page Styles Four</a>
                                                  <li><a href="index5-one-page.html">One Page Styles Five</a>
                                                  </li>
                                              </ul>
                                          </li>
                                      </ul>
                                  </li>*/}
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  {/*<li class="dropdown">
                                      <a href="#">Pages</a>
                                      <ul>
                                          <li><a href="team.html">Team</a></li>
                                          <li><a href="testimonials.html">Testimonials</a></li>
                                          <li><a href="faq.html">Faq</a></li>
                                          <li><a href="404.html">404 Error</a></li>
                                          <li><a href="coming-soon.html">Coming Soon</a></li>
                                      </ul>
                                  </li>*/}
                  <li className="dropdown">
                    <Link to="">Services</Link>
                    <ul>
                      <li>
                        <Link to="/services">All Services</Link>
                      </li>
                      <li>
                        <a href="/airporttransfer">Airport transfer</a>
                      </li>
                      <li>
                        <a href="/busrental">Bus | Minibus | Van rental</a>
                      </li>
                      <li>
                        <a href="/chauffeurservices">Chauffeur Service</a>
                      </li>
                      <li>
                        <a href="/limousineservice">
                          Limousine Service Dubai
                        </a>
                      </li>
                      <li>
                        <a href="/hourlyrateservice">
                          Hourly Rate Service
                        </a>
                      </li>
                      <li>
                        <a href="groundtransportation">
                          Ground Transportation
                        </a>
                      </li>
                    </ul>
                  </li>
                  {/*<li class="dropdown">
                                      <a href="#">projects</a>
                                      <ul>
                                          <li><a href="projects.html">Projects</a></li>
                                          <li><a href="project-details.html">Project Details</a></li>
                                      </ul>
                                  </li>*/}
                  <li>
                    <Link to="/ourfleet">Our Fleet</Link>
                  </li>
                  <li>
                    <Link to="gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
           
           
            <div className="main-menu__right">
              <div className="main-menu__search-btn-box">
                {/* <a href="#" class="main-menu__search search-toggler icon-search"></a>*/}
                <div className="main-menu__btn-box">
                  <button 
                    id="btnGetQuote"
                    onClick={() => handleClick()}
                    className="search-toggler main-menu__btn thm-btn"
                    style={{
                      background:
                        "radial-gradient(circle, #ffdb57 0, #c3963b 100%)"
                    }}
                  >
                    GET A QUOTE
                    <span className="icon-arrow-left" />
                  </button>
              
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <div className="stricky-header stricked-menu main-menu">
    <div className="sticky-header__content" id="sticky">

    </div>
  </div>
 

  
  {isOpen &&(
  <div className="search-popup active" id="modalpopup">
    
  <div className="search-popup__overlay search-toggler" />
  {/* /.search-popup__overlay */}
  <div className="search-popup__content">
    <form action="#">
      <div className="al_home">
        
        <div
          id="onlineBooking"
          className="overlay"
          style={{ display: "block" }}
        >
          
          <div className="popup">
            <a
              className="close"
              style={{ cursor: "pointer" }}
              onClick={() => handleClose()}
            >
              ×
            </a>
            
            <div className="content">
              <div className="al_forms">
                <div className="al_cstmContainer">
                  <input id="input" className="input" type="checkbox" />
                  <label htmlFor="input" className="toggle">
                    <span className="text sign-text">One way</span>
                    
                    <span className="icon">
                      <svg
                        className="arrow"
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 16 16"
                        height={32}
                        width={32}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                        />
                      </svg>
                    </span>
                    <span className="text log-text">By the hour</span>
                  </label>
                  <div className="card">
                    <div className="content sign">
                      <h2 className="title">One way</h2>
                      <div className="fields">
                        <label className="field">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="location-dot"
                            className="svg-inline--fa fa-location-dot "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                            />
                          </svg>
                         
                           {isLoaded
        &&(
        <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged} 
        >
            <input
                            style={{ paddingLeft: 30 }}
                            type="text"
                            autoComplete='off'
                            className="name"
                            id="txtFromAddress"
                            name='txtFromAddress'
                            placeholder="From : Address, airport, hotel, ..."
                            onChange={handleInput}
                          />
        </StandaloneSearchBox>
    )}
     {errors.txtFromAddress && <p style={{color:'red', fontSize:"13px"}}>{errors.txtFromAddress}</p>}
                         
                        </label>
                       
                        <label className="field" style={{ margin: 0 }}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="location-dot"
                            className="svg-inline--fa fa-location-dot "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                            />
                          </svg>
                        
                                 {isLoaded
        &&(
        <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged} 
        >
            <input
                            style={{ paddingLeft: 30 }}
                            type="text"
                            autoComplete="off"
                            id="txtToAddress"
                            name='txtToAddress'
                            className="email"
                            placeholder="To : Address, airport, hotel, ..."
                            onChange={handleInput}
                          />
        </StandaloneSearchBox>
    )}
       {errors.txtToAddress && <p style={{color:'red', fontSize:"13px"}}>{errors.txtToAddress}</p>}
                          <ul />
                          <p
                            className="requiredField"
                            id="txtToAddressError"
                            style={{ display: "none" }}
                          >
                            This field is required
                          </p>
                        </label>
                        
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="calendar-days"
                            className="svg-inline--fa fa-calendar-days "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"
                            />
                          </svg>
                          <span className="customDatepicker">
                            <div className="react-datepicker-wrapper">
                              <div className="react-datepicker__input-container ">
                                <span
                                  role="alert"
                                  aria-live="polite"
                                  className="react-datepicker__aria-live"
                                />
                                <input
                                  style={{ paddingLeft: 30 }}
                                  type="date"
                                  id="startDateoneway"
                                  name='startDateoneway'
                                  placeholder="Select Date"
                                  className="password"
                                  defaultValue=""
                                  onChange={handleInput}
                                  onClick={(e) => e.currentTarget.showPicker() }
                                />
                                 {errors.startDateoneway && <p style={{color:'red', fontSize:"13px"}}>{errors.startDateoneway}</p>}
                              </div>
                              
                            </div>
                           
                          </span>
                          <p
                            className="requiredField"
                            id="startDateonewayError"
                            style={{ display: "none" }}
                          >
                            This field is required
                          </p>
                        </label>
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="clock"
                            className="svg-inline--fa fa-clock "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                            />
                          </svg>
                          <span className="customDatepicker">
                            <div className="react-datepicker-wrapper">
                              <div className="react-datepicker__input-container ">
                                <span
                                  role="alert"
                                  aria-live="polite"
                                  className="react-datepicker__aria-live"
                                />
                                <input
                                  style={{ paddingLeft: 30 }}
                                  type="time"
                                  id="startTimeoneway"
                                  name="startTimeoneway"
                                  placeholder="Select Time"
                                  className="password"
                                  defaultValue=""
                                  onChange={handleInput}
                                  onClick={(e) => e.currentTarget.showPicker() }
                                />
                                 {errors.startTimeoneway && <p style={{color:'red', fontSize:"13px"}}>{errors.startTimeoneway}</p>}
                              </div>
                            </div>
                          </span>
                          <p
                            className="requiredField"
                            id="startDateonewayError"
                            style={{ display: "none" }}
                          >
                            This field is required
                          </p>
                        </label>
                        <label className="field" style={{ margin: 0 }}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="car"
                            className="svg-inline--fa fa-car "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                            />
                          </svg>
                          <select
                            placeholder="Select Vehicle"
                            className="password "
                            id="oneWayVehicle"
                            name='oneWayVehicle'
                            onChange={handleInput}
                          >
                            <option value={0}>Select Vehicle</option>
                            <option value="BMW 7 series">BMW 7 series</option>
                            <option value="50 Seater Luxury Bus">50 Seater Luxury Bus</option>
                            <option value="35 Seater Luxury Bus">35 Seater Luxury Bus</option>
                            <option value="Audi A6">Audi A6</option>
                            <option value="Rolls Royce Ghost">Rolls Royce Ghost</option>
                            <option value="Rolls-Royce Cullinan">Rolls-Royce Cullinan</option>
                            <option value="Bentley Flying Spur">Bentley Flying Spur </option>
                            <option value="Mercedes S Class">Mercedes S Class </option>
                            <option value="Audi A8">Audi A8 </option>
                            <option value="Cadillac Escalade">Cadillac Escalade</option>
                            <option value="Mercedes V class">Mercedes V class </option>
                            <option value="Honda Odyssey">Honda Odyssey</option>
                            <option value="Toyota Previa">Toyota Previa</option>
                            <option value="Toyota Granvia">Toyota Granvia</option>
                            <option value="Lexus ES 350">Lexus ES 350</option>
                            <option value="Mercedes E Class">Mercedes E Class</option>
                            <option value="BMW 5 Series">BMW 5 Series</option>
                            <option value="Mercedes Sprinter">Mercedes Sprinter</option>
                            <option value="Toyota Hiace<">Toyota Hiace</option>
                            <option value="Toyota Coaster">Toyota Coaster </option>
                            <option value="Rolls Royce Phantom">Rolls Royce Phantom</option>
                            <option value="Strech Limousine">Strech Limousine </option>
                            <option value="GMC Yukon">GMC Yukon</option>
                            <option value="Tesla Model 3">Tesla Model 3 </option>
                            <option value="Tesla Model Y">Tesla Model Y</option>
                          </select>
                          {errors.oneWayVehicle && <p style={{color:'red', fontSize:"13px"}}>{errors.oneWayVehicle}</p>}
                          <p
                            className="requiredField"
                            id="oneWayVehicleError"
                            style={{ display: "none" }}
                          >
                            This field is required
                          </p>
                        </label>
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="people-group"
                            className="svg-inline--fa fa-people-group "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path
                              fill="currentColor"
                              d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"
                            />
                          </svg>
                          <input
                            style={{ paddingLeft: 30 }}
                            type="text"
                            className="password"
                            placeholder=" Allowed Passengers"
                            defaultValue=""
                            id="txtOneWayPassengers"
                            name="txtOneWayPassengers"
                          />
                        </label>
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="bag-shopping"
                            className="svg-inline--fa fa-bag-shopping "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M160 112c0-35.3 28.7-64 64-64s64 28.7 64 64v48H160V112zm-48 48H48c-26.5 0-48 21.5-48 48V416c0 53 43 96 96 96H352c53 0 96-43 96-96V208c0-26.5-21.5-48-48-48H336V112C336 50.1 285.9 0 224 0S112 50.1 112 112v48zm24 48a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm152 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
                            />
                          </svg>
                          <input
                            style={{ paddingLeft: 30 }}
                            type="text"
                            className="password"
                            placeholder="Max.Baggage"
                            defaultValue=""
                            id="txtOneWayBaggage"
                            name="txtOneWayBaggage"
                          />
                        </label>
                      </div>
                      <div className="submit">
                        <button
                          type="button"
                          className="button-submit"
                          id="myBooking"
                          onClick={() => SubmitQuote()}
                        >
                          Get A Quote
                        </button>
                        
                      </div>
                    </div>
                    
                    <div>
                      
                    </div>
                    <div className="content log">
                      <h2 className="title">By the hour</h2>
                      <div className="fields">
                        <label className="field">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="location-dot"
                            className="svg-inline--fa fa-location-dot "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                            />
                          </svg>

{isLoaded
        &&(
        <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged} 
        >
            <input
                            style={{ paddingLeft: 30 }}
                            type="text"
                        autoComplete='off'
                            className="name"
                            id="pickupaddresshour"
                            name='pickupaddresshour'
                            placeholder="Pick me up at"
                            onChange={handleInputHour}
                          />
        </StandaloneSearchBox>
    )}
    {errors.pickupaddresshour && <p style={{color:'red', fontSize:"13px"}}>{errors.pickupaddresshour}</p>}
                         
                        </label>
                        <label className="field" style={{ margin: 0 }}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="location-dot"
                            className="svg-inline--fa fa-location-dot "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                            />
                          </svg>
                          <select
                            placeholder="Select Vehicle"
                            className="password"
                            id="duration"
                            name='duration'
                            onChange={handleInputHour}
                          >
                            <option value={1}>
                              Chauffeur Services 5 Hours Dubai City
                            </option>
                            <option value={2}>
                              Chauffeur Services 10 Hours Dubai City
                            </option>
                            <option value={3}>
                              Chauffeur Services 10 Hours Abu Dhabi City
                            </option>
                            <option value={4}>
                              Chauffeur Services 10 Hours Fujairah
                            </option>
                            <option value={5}>
                              Chauffeur Services 10 Hours Al Ain
                            </option>
                            <option value={6}>
                              Chauffeur Services 5 Hours Abu Dhabi City
                            </option>
                          </select>
                          {errors.duration && <p style={{color:'red', fontSize:"13px"}}>{errors.duration}</p>}
                        </label>
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="calendar-days"
                            className="svg-inline--fa fa-calendar-days "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"
                            />
                          </svg>
                          <span className="customDatepicker">
                            <div className="react-datepicker-wrapper">
                              <div className="react-datepicker__input-container ">
                                <span
                                  role="alert"
                                  aria-live="polite"
                                  className="react-datepicker__aria-live"
                                />
                               
                                <input
                                  style={{ paddingLeft: 30 }}
                                  type="date"
                                  id="hourdate"
                                  name='hourdate'
                                  placeholder="Select Date"
                                  className="password react-datepicker-ignore-onclickoutside"
                                  defaultValue=""
                                  onClick={(e) => e.currentTarget.showPicker() }
                                  onChange={handleInputHour}
                                />
                                {errors.hourdate && <p style={{color:'red', fontSize:"13px"}}>{errors.hourdate}</p>}
                              </div>
                            </div>
                            
                          </span>
                          <p
                            className="requiredField"
                            id="hourdateError"
                            style={{ display: "none" }}
                          >
                            This field is required
                          </p>
                        </label>
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="clock"
                            className="svg-inline--fa fa-clock "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                            />
                          </svg>
                          <input
                                  style={{ paddingLeft: 30 }}
                                  type="time"
                                  id="hourTime"
                                  name='hourTime'
                                  placeholder="Select Time"
                                  className="password"
                                  defaultValue=""
                                  onClick={(e) => e.currentTarget.showPicker() }
                                  onChange={handleInputHour}
                                />
                                {errors.hourTime && <p style={{color:'red', fontSize:"13px"}}>{errors.hourTime}</p>}
                          
                        </label>
                        <label className="field " style={{ margin: 0 }}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="car"
                            className="svg-inline--fa fa-car "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                            />
                          </svg>
                          <select
                            placeholder="Select Vehicle"
                            className="password"
                            id="hourVehicle"
                            name="hourVehicle"
                            onChange={handleInputHour}
                          >
                            <option value={0}>Select Vehicle</option>
                            <option value={63}>BMW 7 series</option>
                            <option value={76}>50 Seater Luxury Bus</option>
                            <option value={75}>35 Seater Luxury Bus</option>
                            <option value={64}>Audi A6</option>
                            <option value={30}>Rolls Royce Ghost</option>
                            <option value={31}>Rolls-Royce Cullinan</option>
                            <option value={61}>Bentley Flying Spur </option>
                            <option value={79}>Mercedes S Class </option>
                            <option value={84}>Audi A8 </option>
                            <option value={8}>Cadillac Escalade</option>
                            <option value={68}>Mercedes V class </option>
                            <option value={67}>Honda Odyssey</option>
                            <option value={66}>Toyota Previa</option>
                            <option value={87}>Toyota Granvia</option>
                            <option value={69}>Lexus ES 350</option>
                            <option value={34}>Mercedes E Class</option>
                            <option value={70}>BMW 5 Series</option>
                            <option value={72}>Mercedes Sprinter</option>
                            <option value={73}>Toyota Hiace</option>
                            <option value={74}>Toyota Coaster </option>
                            <option value={29}>Rolls Royce Phantom</option>
                            <option value={77}>Strech Limousine </option>
                            <option value={78}>GMC Yukon</option>
                            <option value={82}>Tesla Model 3 </option>
                            <option value={81}>Tesla Model Y</option>
                          </select>
                          {errors.hourVehicle && <p style={{color:'red', fontSize:"13px"}}>{errors.hourVehicle}</p>}
                          <p
                            className="requiredField"
                            id="hourVehicleError"
                            style={{ display: "none" }}
                          >
                            This field is required
                          </p>
                        </label>
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="people-group"
                            className="svg-inline--fa fa-people-group "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path
                              fill="currentColor"
                              d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"
                            />
                          </svg>
                          <input
                            type="text"
                            className="password"
                            placeholder=" Allowed Passengers"
                            defaultValue=""
                          />
                        </label>
                        <label
                          className="field"
                          style={{ width: "50%", float: "left" }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="bag-shopping"
                            className="svg-inline--fa fa-bag-shopping "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M160 112c0-35.3 28.7-64 64-64s64 28.7 64 64v48H160V112zm-48 48H48c-26.5 0-48 21.5-48 48V416c0 53 43 96 96 96H352c53 0 96-43 96-96V208c0-26.5-21.5-48-48-48H336V112C336 50.1 285.9 0 224 0S112 50.1 112 112v48zm24 48a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm152 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
                            />
                          </svg>
                          <input
                            type="text"
                            className="password"
                            placeholder="Max.Baggage"
                            defaultValue=""
                          />
                        </label>
                      </div>
                      <div className="submit">
                        <button type='button' className="button-submit"
                         onClick={() => SubmitHourQuote()}>
                          Get A Quote
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.search-popup__content */}
      </div>
    </form>
  </div>
  
</div>

 )}
  </>
    );
}
export default Header;