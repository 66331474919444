
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import React,{useEffect,useState,useRef} from 'react';
import emailjs from '@emailjs/browser';
import { useLocation,useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { ThreeDot } from "react-loading-indicators";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const libraries = ['places'];

function BookRizChauffeur() {


    const inputRef = useState();
    const form = useRef();
    const navigate = useNavigate()
    const location = useLocation();
    const  fromHome = location.state;
    const[oneWayVehicle, setoneWayVehicle]=useState();
    //let data = fromHome.data;
    console.log("Value:", location.state)

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyApLXk59hUqmQkHwBWa3BRVftYvfuJuTqU',
        libraries
    });

  

    var map;
    function initMap() {
  // The map, centered on Central Park
  const center = {lat: 40.774102, lng: -73.971734};
  const options = {zoom: 15, scaleControl: true, center: center};
  map = new window.google.maps.Map(
      document.getElementById('map'), options);
  // Locations of landmarks
  const dakota = {lat: 40.7767644, lng: -73.9761399};
  const frick = {lat: 40.771209, lng: -73.9673991};
  // The markers for The Dakota and The Frick Collection
  var mk1 = new window.google.maps.Marker({position: dakota, map: map});
  var mk2 = new window.google.maps.Marker({position: frick, map: map});
    var distance = haversine_distance(mk1, mk2);
  document.getElementById('msg').innerHTML = "Distance between markers: " + distance.toFixed(2) + " mi.";
}

function haversine_distance(mk1, mk2) {
    var R = 3958.8; // Radius of the Earth in miles
    var rlat1 = mk1.position.lat() * (Math.PI/180); // Convert degrees to radians
    var rlat2 = mk2.position.lat() * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (mk2.position.lng()-mk1.position.lng()) * (Math.PI/180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    return d;
  }

  //let fromAdd =''
  //let toAdd =''

  const [fromAdd,setFromAdd]=useState()
  const [toAdd,setToAdd]=useState()
  const [date,setDate]=useState()
  const [time,srtTime]=useState()
  const[passengername,setPassengername]=useState()
  const[email,setEmail]=useState()
  const[phone,setPhone]=useState()

  const[seats,setSeats]=useState()
  const[baggage,setBaggage]=useState()

  useEffect(() => {
 
    if(location.state!=null)
    {
    setFromAdd(location.state.fromAdd)
    setToAdd(location.state.toAdd)
    setDate(location.state.startDate)
    srtTime(location.state.startTime)
    setoneWayVehicle(location.state.oneWayVehicle)
    setSeats(location.state.seats)
    setBaggage(location.state.baggage)
    }
  
},[]);

const [errors,SetErrors]=useState({})

const [values, setValues] = useState({
  passengername:'',
  email:'',
  phone:''
})

const onHandlePName=(e)=>
{ 
  setValues(values => ({ ...values, [e.target.name]: e.target.value }));
  if(!values.passengername.trim())
    {
        errors.passengername='This field is required';
    }
    else
    {
      errors.passengername="";
    }
}

const onHandleEmail=(e)=>
  {
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
     if(!values.email.trim())
      {
        errors.email='This field is required';
      }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
      else{
        errors.email="";
      }
  }

  const onHandlePhone=(e)=>
    {
      setValues(values => ({ ...values, [e.target.name]: e.target.value }));
       if(!values.phone.trim())
        {
          errors.phone='This field is required';
        }
        else
        {
          errors.phone="";
        }
    }

  


const validateValues = (inputValues) => {
  const errors={};
  if(inputValues.passengername==="")
    {
        errors.passengername='This field is required';
    }
    else if(inputValues.email==="")
    {
      errors.email='This field is required';
    }
    else if(inputValues.phone==="")
      {
        errors.phone='This field is required';
      }
    else
    {
      errors.passengername='';
      errors.email='';
      errors.phone='';
    }
    return  errors;
};

    const handlePlaceChanged = () => { 
        console.log(inputRef)
       /* const [ place ] = inputRef.current.getPlaces();;
        if(place) { 
            console.log(place.formatted_address)
            console.log(place.geometry.location.lat())
            console.log(place.geometry.location.lng())
        } */
    }

    const onHandleVehicle=(e)=>
    {
      setoneWayVehicle(e.target.value);
      setSeats("3 Seats")
      setBaggage("Baggage 2")
    }

    function sendEmail(e) {
      //sendCusMail();
       //e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
       SetErrors(validateValues(values));
       if(values.passengername!=="" && values.email!=="" && values.phone!=="")
       {
        <ThreeDot style={{textAlign:'center'}} variant="pulsate" color="#a6cc31" size="medium" text="" textColor="" />
        emailjs.sendForm('service_eewfb3n', 'template_ff6fxai',form.current, '2hMW2EkTDEzbv167U')
        .then((result) => {
         
          //swal("Your booking has been completed, our representative contact soon");
          swal({
            title: "Booking Confirmation!",
            text: "Your booking has been completed, our representative contact soon...",
            type: "success"
        }).then(function() {
            //window.location = "redirectURL";
            navigate("/");//This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            window.location.reload();
        });
          
         
        }, (error) => {
           alert(error)
             console.log(error.text);
         });
       }
      
     }

 
    
    
    return (
        <div className="page-wrapper" style={{backgroundColor:'white'}}>
        {/*Page Header Start*/}
        <>
  {/*Services Two Start */}

    {/*Contact One Start */}
    <section className="contact-one" style={{display:'block',backgroundColor: "white", paddingTop: 40}}>
    <div className="container" style={{backgroundColor: "#fff"}}>
    <div className="section-title text-left pb_heading">
        <div className="section-title__tagline-box">
          <span
            className="section-title__tagline"
            style={{ color: "black", borderBottom: "5px solid goldenrod" }}
          >
            Booking Details
          </span>
        </div>
        <br />
      </div>

      <div className="row" style={{backgroundColor: "#fff"}}>
      <div className="col-xl-6">
          <div
            className="contact-one__right wow slideInRight" 
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          >
            <Tabs
      defaultActiveKey="OneWay"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="OneWay" title="One Way">
      <div className="bookChauffeur">
                  <div className="row wrap">
                    <div className="col-md-6 bookingFields">
                      <label htmlFor="From">From</label>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="location-dot"
                        className="svg-inline--fa fa-location-dot "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512" style={{top:'20px', left:'20px', color:'goldenrod'}}
                      >
                        <path
                          fill="currentColor"
                          d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                        />
                      </svg>
                      {isLoaded
        &&(
        <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged} 
        >
          

<input
                        id="from"
                        name="from"
                        style={{ paddingLeft: '30px',paddingTop:'15px'}}
                        autoComplete="off"
                        type="text"
                        value={fromAdd}
                        className="cool"
                        placeholder="Address, Airport, Hotel..."
                        defaultValue=""
                      />
        </StandaloneSearchBox>
    )}
                    
                      <ul />
                      <p className="requiredField" style={{ display: "none" }}>
                        This field is required
                      </p>
                    </div>
                    <div className="col-md-6 bookingFields">
                      <label htmlFor="to">To</label>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="location-dot"
                        className="svg-inline--fa fa-location-dot "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        style={{top:'20px', left:'20px', color:'goldenrod'}}
                      >
                        <path
                          fill="currentColor"
                          d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                        />
                      </svg>
                      {isLoaded
        &&(
        <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged} 
        >
          

          <input
                      style={{ paddingLeft: '30px',paddingTop:'15px'}}
                        id="to"
                        name="to"
                        type="text"
                        autoComplete="off"
                        className="cool"
                        placeholder="Address, Airport, Hotel..."
                        defaultValue=""
                        value={toAdd}
                      />
        </StandaloneSearchBox>
    )}
                     
                      <ul />
                      <p className="requiredField" style={{ display: "none" }}>
                        This field is required
                      </p>
                    </div>
                    <div className="col-md-6 bookingFields">
                      <label htmlFor="Date">Date </label>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="calendar-days"
                        className="svg-inline--fa fa-calendar-days "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={{top:'20px', left:'20px', color:'goldenrod'}}
                      >
                        <path
                          fill="currentColor"
                          d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"
                        />
                      </svg>
                      <div className="react-datepicker-wrapper">
                        <div className="react-datepicker__input-container ">
                          <span
                            role="alert"
                            aria-live="polite"
                            className="react-datepicker__aria-live"
                          />
                          <input
                          style={{ paddingLeft: '30px',paddingTop:'10px', width:'100%', fontSize:'13px'}}
                            type="date"
                            id="Date"
                            name="Date"
                            placeholder="Select Date"
                            autoComplete="off"
                            className=""
                            defaultValue=""
                            value={date}
                            onClick={(e) => e.currentTarget.showPicker() }
                          />

                          
                        </div>
                      </div>
                      <p className="requiredField" style={{ display: "none" }}>
                        This field is required
                      </p>
                    </div>
                    <div className="col-md-6 bookingFields">
                      <label htmlFor="Time">Time</label>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="clock"
                        className="svg-inline--fa fa-clock "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{top:'20px', left:'20px', color:'goldenrod'}}

                      >
                        <path
                          fill="currentColor"
                          d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                        />
                      </svg>
                      <input
                                  style={{ paddingLeft: 30, paddingTop:"8px",width:"100%", fontSize:"13px"}}
                                  type="time"
                                  id="time"
                                  name="time"
                                  placeholder="Select Time"
                                  className="password"
                                  defaultValue=""
                                  value={time}
                                  onClick={(e) => e.currentTarget.showPicker() }
                                />
                    </div>
                    <div className="col-md-12" >
                      <div className=" bookWay" >
                        <label className="switch">
                          <input type="checkbox" name="carBooking" />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div
                        className="col-md-12 switchLeft mt-1"
                        style={{ display: "block" }}
                      >
                        <span style={{display:'none'}}> Add Return Way</span>
                      </div>
                      <div
                        className="col-md-12 mt-1 switchRight"
                        style={{ display: "none" }}
                      >
                        <span> Cancel Return Way</span>
                        <div className="row">
                          <div className="col-md-6 bookingFields">
                            <label htmlFor="returnAddress1">Return From</label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="location-dot"
                              className="svg-inline--fa fa-location-dot "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              style={{top:'20px', left:'20px', color:'goldenrod'}}

                            >
                              <path
                                fill="currentColor"
                                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                              />
                            </svg>
                            <input
                              id="returnAddress1"
                              autoComplete="off"
                              type="text"
                              className="cool"
                              placeholder="Address, Airport, Hotel..."
                              defaultValue=""
                            />
                            <ul />
                          </div>
                          <div className="col-md-6 bookingFields">
                            <label htmlFor="returnAddress2">Return To</label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="location-dot"
                              className="svg-inline--fa fa-location-dot "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentColor"
                                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                              />
                            </svg>
                            <input
                              id="returnAddress2"
                              autoComplete="off"
                              type="text"
                              className="cool"
                              placeholder="Address, Airport, Hotel..."
                              defaultValue=""
                            />
                            <ul />
                          </div>
                          <div className="col-md-6 bookingFields">
                            <label htmlFor="ReturnDate">Return Date</label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="calendar-days"
                              className="svg-inline--fa fa-calendar-days "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"
                              />
                            </svg>
                            <div className="react-datepicker-wrapper">
                              <div className="react-datepicker__input-container ">
                                <span
                                  role="alert"
                                  aria-live="polite"
                                  className="react-datepicker__aria-live"
                                />
                                <input
                                  type="text"
                                  id="ReturnDate"
                                  placeholder="Select Date"
                                  className=""
                                  defaultValue=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 bookingFields">
                            <label htmlFor="returnTime">Return Time</label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="clock"
                              className="svg-inline--fa fa-clock "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                              />
                            </svg>
                            <select
                              placeholder="Time"
                              id="returnTime"
                              className="cool"
                              name="returnTime"
                            >
                              <option value={0}>Select Time</option>
                              <option value={'00'}>00:00</option>
                              <option value={'01'}>01:00</option>
                              <option value={'02'}>02:00</option>
                              <option value={'03'}>03:00</option>
                              <option value={'04'}>04:00</option>
                              <option value={'05'}>05:00</option>
                              <option value={'06'}>06:00</option>
                              <option value={'07'}>07:00</option>
                              <option value={'08'}>08:00</option>
                              <option value={'09'}>09:00</option>
                              <option value={10}>10:00</option>
                              <option value={11}>11:00</option>
                              <option value={12}>12:00</option>
                              <option value={13}>13:00</option>
                              <option value={14}>14:00</option>
                              <option value={15}>15:00</option>
                              <option value={16}>16:00</option>
                              <option value={17}>17:00</option>
                              <option value={18}>18:00</option>
                              <option value={19}>19:00</option>
                              <option value={20}>20:00</option>
                              <option value={21}>21:00</option>
                              <option value={22}>22:00</option>
                              <option value={23}>23:00</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 switchLeft mt-1">
                        <div className="col-md-12 bookingFields iconSelect">
                          <label htmlFor="SelectVehicle">Select Vehicle</label>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="car"
                            className="svg-inline--fa fa-car "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            style={{top:'20px', left:'20px', color:'goldenrod'}}
                          >
                            <path
                              fill="currentColor"
                              d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                            />
                          </svg>
                          <select className="cool" style={{ paddingLeft: '40px',paddingTop:'15px'}} id="oneWayVehicle"
                          name="oneWayVehicle" value={oneWayVehicle} onChange={onHandleVehicle}>
                            
                          <option value={0}>Select Vehicle</option>
                            <option value="BMW 7 series">BMW 7 series</option>
                            <option value="50 Seater Luxury Bus">50 Seater Luxury Bus</option>
                            <option value="35 Seater Luxury Bus">35 Seater Luxury Bus</option>
                            <option value="Audi A6">Audi A6</option>
                            <option value="Rolls Royce Ghost">Rolls Royce Ghost</option>
                            <option value="Rolls-Royce Cullinan">Rolls-Royce Cullinan</option>
                            <option value="Bentley Flying Spur">Bentley Flying Spur </option>
                            <option value="Mercedes S Class">Mercedes S Class </option>
                            <option value="Audi A8">Audi A8 </option>
                            <option value="Cadillac Escalade">Cadillac Escalade</option>
                            <option value="Mercedes V class">Mercedes V class </option>
                            <option value="Honda Odyssey">Honda Odyssey</option>
                            <option value="Toyota Previa">Toyota Previa</option>
                            <option value="Toyota Granvia">Toyota Granvia</option>
                            <option value="Lexus ES 350">Lexus ES 350</option>
                            <option value="Mercedes E Class">Mercedes E Class</option>
                            <option value="BMW 5 Series">BMW 5 Series</option>
                            <option value="Mercedes Sprinter">Mercedes Sprinter</option>
                            <option value="Toyota Hiace<">Toyota Hiace</option>
                            <option value="Toyota Coaster">Toyota Coaster </option>
                            <option value="Rolls Royce Phantom">Rolls Royce Phantom</option>
                            <option value="Strech Limousine">Strech Limousine </option>
                            <option value="GMC Yukon">GMC Yukon</option>
                            <option value="Tesla Model 3">Tesla Model 3 </option>
                            <option value="Tesla Model Y">Tesla Model Y</option>
                          </select>
                        </div>
                        <div className="selectedvehicleSec">
                          <div className="col-md-12 bookingFields">
                            <label htmlFor="SelectVehicle">
                              Selected Vehicle
                            </label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="car"
                              className="svg-inline--fa fa-car "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              style={{top:'20px', left:'20px', color:'black'}}
                            >
                              <path
                                fill="currentColor"
                                d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                              />
                            </svg>
                            <div id="SelectVehicle" className="cool bookedCars" >
                              <img
                                alt="BMW 5 Series"
                                loading="lazy"
                                width={50}
                                height={50}
                                decoding="async"
                                data-nimg={1}
                                srcSet="/_next/image?url=https%3A%2F%2Fapi.altairchauffeur.com%2Fuploads%2Fvehicle%2Fbmw%205.png&w=64&q=75 1x, /_next/image?url=https%3A%2F%2Fapi.altairchauffeur.com%2Fuploads%2Fvehicle%2Fbmw%205.png&w=128&q=75 2x"
                                src="/_next/image?url=https%3A%2F%2Fapi.altairchauffeur.com%2Fuploads%2Fvehicle%2Fbmw%205.png&w=128&q=75"
                                style={{ color: "transparent", display:'none' }}
                              />
                              <p >{oneWayVehicle}</p>
                              <i>
                                <b>Luxury Class</b>
                              </i>
                              <h6>
                                <span>{seats}</span>&nbsp;&nbsp;{""}
                                <span> {baggage} </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ display: "none" }}>
                      <div className=" bookWay">
                        <label className="switch">
                          <input type="checkbox" name="carBooking1" />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div
                        className="col-md-12 switchLeft1 mt-1"
                        style={{ display: "block" }}
                      >
                        <span> Flight or Train Number</span>
                      </div>
                      <div
                        className="col-md-12 mt-1 switchRight1"
                        style={{ display: "none" }}
                      >
                        <span> Cancel</span>
                        <div className="row">
                          <div className="col-md-6 bookingFields">
                            <label htmlFor="Flight">Flight</label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plane"
                              className="svg-inline--fa fa-plane "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M482.3 192c34.2 0 93.7 29 93.7 64c0 36-59.5 64-93.7 64l-116.6 0L265.2 495.9c-5.7 10-16.3 16.1-27.8 16.1l-56.2 0c-10.6 0-18.3-10.2-15.4-20.4l49-171.6L112 320 68.8 377.6c-3 4-7.8 6.4-12.8 6.4l-42 0c-7.8 0-14-6.3-14-14c0-1.3 .2-2.6 .5-3.9L32 256 .5 145.9c-.4-1.3-.5-2.6-.5-3.9c0-7.8 6.3-14 14-14l42 0c5 0 9.8 2.4 12.8 6.4L112 192l102.9 0-49-171.6C162.9 10.2 170.6 0 181.2 0l56.2 0c11.5 0 22.1 6.2 27.8 16.1L365.7 192l116.6 0z"
                              />
                            </svg>
                            <input
                              id="Flight"
                              type="text"
                              name="flight"
                              className="cool"
                              placeholder="Address, Airport, Hotel..."
                              defaultValue=""
                            />
                          </div>
                          <div className="col-md-6 bookingFields">
                            <label htmlFor="Train">Train</label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="train"
                              className="svg-inline--fa fa-train "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M96 0C43 0 0 43 0 96V352c0 48 35.2 87.7 81.1 94.9l-46 46C28.1 499.9 33.1 512 43 512H82.7c8.5 0 16.6-3.4 22.6-9.4L160 448H288l54.6 54.6c6 6 14.1 9.4 22.6 9.4H405c10 0 15-12.1 7.9-19.1l-46-46c46-7.1 81.1-46.9 81.1-94.9V96c0-53-43-96-96-96H96zM64 96c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V96zM224 288a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                              />
                            </svg>
                            <input
                              id="Train"
                              type="text"
                              name="train"
                              className="cool"
                              placeholder="Address, Airport, Hotel..."
                              defaultValue=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ display: "none" }}>
                      <div className=" bookWay">
                        <label className="switch">
                          <input type="checkbox" name="carBooking2" />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div
                        className="col-md-12 switchLeft2 mt-1"
                        style={{ display: "block" }}
                      >
                        <span> Meeting with a name sign is required</span>
                      </div>
                      <div
                        className="col-md-12 mt-1 switchRight2"
                        style={{ display: "none" }}
                      >
                        <span> Cancel</span>
                        <div className="row">
                          <div className="col-md-12 bookingFields">
                            <label htmlFor="nameSign">Name Sign *</label>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="circle-user"
                              className="svg-inline--fa fa-circle-user "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"
                              />
                            </svg>
                            <input
                              id="nameSign"
                              name="nameSign"
                              type="text"
                              className="cool"
                              placeholder="Fill in Name Sign."
                              defaultValue=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ display: "none" }}>
                      <div className=" bookWay">
                        <label className="switch">
                          <input type="checkbox" name="carBooking3" />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div
                        className="col-md-12 switchLeft3 mt-1"
                        style={{ display: "block" }}
                      >
                        <span> Promo Code</span>
                      </div>
                    </div>
                    <div
                      className="col-md-12 mt-1 switchRight3"
                      style={{ display: "none" }}
                    >
                      <span> Cancel Promo Code </span>
                      <div className="row">
                        <div className="col-md-12 bookingFields">
                          <label htmlFor="promocode"> Promo Code</label>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="gift"
                            className="svg-inline--fa fa-gift "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"
                            />
                          </svg>
                          <input
                            id="promocode"
                            name="promocode"
                            type="text"
                            className="cool"
                            placeholder="Promo Code"
                            defaultValue=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row wrap">
                    <div className="col-md-12 bookingFields">
                      <label htmlFor="PassengerName">Passenger's Name</label>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="user-large"
                        className="svg-inline--fa fa-user-large "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{top:'20px', left:'20px', color:'goldenrod'}}
                      >
                        <path
                          fill="currentColor"
                          d="M256 288A144 144 0 1 0 256 0a144 144 0 1 0 0 288zm-94.7 32C72.2 320 0 392.2 0 481.3c0 17 13.8 30.7 30.7 30.7H481.3c17 0 30.7-13.8 30.7-30.7C512 392.2 439.8 320 350.7 320H161.3z"
                        />
                      </svg>
                      <input
                      style={{ paddingLeft: '30px',paddingTop:'15px'}}

                        id="passengername"
                        autoComplete="off"
                        name="passengername"
                        type="text"
                        className="cool"
                        placeholder="Enter Passenger's Name"
                        defaultValue=""
                        
                        onChange={onHandlePName}
                      />
                     {errors.passengername && <p style={{color:'red', fontSize:"13px"}}>{errors.passengername}</p>}
                    </div>
                    <div className="col-md-6 bookingFields">
                      <label htmlFor="email">Email</label>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="envelopes-bulk"
                        className="svg-inline--fa fa-envelopes-bulk "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                        style={{top:'20px', left:'20px', color:'goldenrod'}}
                      >
                        <path
                          fill="currentColor"
                          d="M128 0C110.3 0 96 14.3 96 32V224h96V192c0-35.3 28.7-64 64-64H480V32c0-17.7-14.3-32-32-32H128zM256 160c-17.7 0-32 14.3-32 32v32h96c35.3 0 64 28.7 64 64V416H576c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H256zm240 64h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zM64 256c-17.7 0-32 14.3-32 32v13L187.1 415.9c1.4 1 3.1 1.6 4.9 1.6s3.5-.6 4.9-1.6L352 301V288c0-17.7-14.3-32-32-32H64zm288 84.8L216 441.6c-6.9 5.1-15.3 7.9-24 7.9s-17-2.8-24-7.9L32 340.8V480c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V340.8z"
                        />
                      </svg>
                      <input
                      style={{ paddingLeft: '30px',paddingTop:'15px'}}
                        id="email"
                        name="email"
                        autoComplete="off"
                        type="text"
                        className="cool"
                        placeholder="Enter email"
                        defaultValue=""
                        onChange={onHandleEmail}
                      />
                       {errors.email && <p style={{color:'red', fontSize:"13px"}}>{errors.email}</p>}
                      </div>
                      
                    <div className="col-md-6 bookingFields">
                      <label htmlFor="Phone">Phone</label>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="phone"
                        className="svg-inline--fa fa-phone "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{top:'20px', left:'20px', color:'goldenrod'}}
                      >
                        <path
                          fill="currentColor"
                          d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                        />
                      </svg>
                      <input
                      style={{ paddingLeft: '30px',paddingTop:'15px'}}
                        id="Phone"
                        name="phone"
                        autoComplete="off"
                        type="text"
                        className="cool"
                        placeholder="Enter Phone"
                        defaultValue=""
                        onChange={onHandlePhone}
                        value={values.phone}
                      />
{errors.phone && <p style={{color:'red', fontSize:"13px"}}>{errors.phone}</p>}
                    </div>
                    
                  </div>
                  <button style={{background:'black', color:'white'}} type="button" id="bnSendMail" onClick={() => sendEmail()}>Book Now</button>
                </div>
      </Tab>
      <Tab eventKey="ByTheHour" title="By The Hour">
        Tab content for Profile
      </Tab>
      
    </Tabs>
            
            <div className="result" />
          </div>
        </div>

        <div className="col-xl-6">
        <div className="mapaltair">
          <iframe
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.3799040595804!2d55.32462817489244!3d25.25780262924429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cda223df2cd%3A0x687e9dfb4888e0f4!2sBusiness%20Village%20-%20Block%20A!5e0!3m2!1sen!2sae!4v1723799137643!5m2!1sen!2sae"
        width="100%"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="mapInformation" style={{backgroundColor:'goldenrod'}}>
        <div id="map" style={{display:'none'}}></div>
          <span style={{color:'white'}} id="msg">Distance : 10 Km</span>
          <span style={{color:'white'}}>Transfer Time : 15 Min</span>
        </div>
        </div>
        
      </div>
    </div>
  </section>
  {/*Contact One End */}
  <br></br>
</>


 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
</div>
    )
};
export default BookRizChauffeur;
