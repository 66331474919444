
function Contact() {
    return (
        <div className="page-wrapper">
          
  {/*Page Header Start*/}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/project/bmw1.png)"
      }}
    ></div>
    <div className="container">
      <div className="page-header__inner">
        <h2>Contact</h2>
        <div className="thm-breadcrumb__box">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <span className="icon-arrow-left" />
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*Contact Two Start*/}
  <section className="contact-two">
    <div
      className="container"
      style={{ backgroundColor: "white", padding: 50 }}
    >
      <div className="section-title text-center">
        <div className="section-title__tagline-box">
          <span className="section-title__tagline">STAY CONNECTED </span>
        </div>
        <h2 className="section-title__title">Request A Quote </h2>
      </div>
      <div className="contact-two__inner">
        <form
          className="contact-form-validated contact-two__form"
          action="assets/inc/sendemail.php"
          method="post"
          noValidate="novalidate"
        >
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-two__input-box">
                <input type="text" name="name" placeholder="Name" required="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-two__input-box">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-two__input-box">
                <input
                  type="text"
                  name="Phone"
                  placeholder="Phone Number"
                  required=""
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contact-two__input-box">
                <div className="select-box">
                  <select className="selectmenu wide">
                    <option selected="selected">Choose a Option</option>
                    <option>Type Of Service 01</option>
                    <option>Type Of Service 02</option>
                    <option>Type Of Service 03</option>
                    <option>Type Of Service 04</option>
                    <option>Type Of Service 05</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="contact-two__input-box text-message-box">
                <textarea
                  name="message"
                  placeholder="Message here.."
                  defaultValue={""}
                />
              </div>
              <div className="contact-two__btn-box">
                <button type="submit" className="thm-btn contact-two__btn">
                  Appointment Now
                  <span className="icon-paper-plan" />
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="result" />
      </div>
    </div>
  </section>
  {/*Contact Two End*/}
  {/*Contact Three End*/}
  <section className="contact-three">
    <div className="container">
      <div className="row">
        {/*Contact Three Single Start*/}
        <div className="col-xl-4 col-lg-4">
          <div className="contact-three__single">
            <div className="contact-three__icon">
              <span className="icon-location" />
            </div>
            <h3 className="contact-three__title">Address</h3>
            <p className="contact-three__text">
              Office #:718,Block-B, Business Vilage,Dubai-UAE{" "}
            </p>
          </div>
        </div>
        {/*Contact Three Single End*/}
        {/*Contact Three Single Start*/}
        <div className="col-xl-4 col-lg-4">
          <div className="contact-three__single">
            <div className="contact-three__icon">
              <span className="icon-call" />
            </div>
            <h3 className="contact-three__title">Phone Number</h3>
            <p className="contact-three__text">
              <a href="tel:6295550129">+971 563847412</a>
            </p>
          </div>
        </div>
        {/*Contact Three Single End*/}
        {/*Contact Three Single Start*/}
        <div className="col-xl-4 col-lg-4">
          <div className="contact-three__single">
            <div className="contact-three__icon">
              <span className="icon-email" />
            </div>
            <h3 className="contact-three__title">E-mail</h3>
            <p className="contact-three__text">
              <a href="mailto:michael.mitc@example.com">
                info@rizchauffeur.com
              </a>
            </p>
          </div>
        </div>
        {/*Contact Three Single End*/}
      </div>
    </div>
  </section>
  {/*Contact Three End*/}
  {/*Google Map Start*/}
  <section className="google-map-one">
    <div className="container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.3799040595804!2d55.32462817489244!3d25.25780262924429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cda223df2cd%3A0x687e9dfb4888e0f4!2sBusiness%20Village%20-%20Block%20A!5e0!3m2!1sen!2sae!4v1723799137643!5m2!1sen!2sae"
        width="100%"
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  </section>
  {/*Google Map End*/}
  {/*CTA Two Start*/}
  <section className="cta-two">
    <div
      className="cta-two__img-1"
      style={{
        backgroundImage: "url(assets/images/resources/cta-two-img-1.jpg)"
      }}
    />
    <div className="cta-two__shape-1 float-bob-x">
      <img src="assets/images/shapes/cta-two-shape-1.png" alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-4" />
        <div className="col-xl-6 col-lg-8">
          <div className="cta-two__right">
            <h3 className="cta-two__title">Subscribe to our newsletter</h3>
            <p className="cta-two__text">
              It is a long established fact that a reader will be distracted by
              the readable
              <br /> content of a page when looking at its layout.
            </p>
            <form className="cta-two__contact-form">
              <div className="cta-two__contact-input-box">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  name="email"
                />
                <button type="submit" className="cta-two__contact-btn thm-btn">
                  Subscribe Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*CTA Two End*/}

 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>
    )
};
export default Contact;