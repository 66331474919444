import { Link,useNavigate} from 'react-router-dom';
import React,{useEffect} from 'react';
function Index() {
  
  useEffect(() => {
  
    
  },[]);
  
  const navigate = useNavigate()

  const btnRollsRoycePhantom =(link)=>
  {
    navigate("/" + link);
  }
  const btnBookRollsRoycePhantom =(vehicle,seats,baggage)=>
    {
      navigate("/bookrizchauffeur", {state: {
        oneWayVehicle:vehicle,seats:seats,baggage:baggage
      }});
    }

    
  

    return (
        <div className="page-wrapper">
        
        <section className="main-slider">
    <div
      className="swiper-container thm-swiper__slider"
      data-swiper-options='{"slidesPerView": 1, "loop": true,
          "effect": "fade",
          "pagination": {
          "el": "#main-slider-pagination",
          "type": "bullets",
          "clickable": true
          },
          "navigation": {
          "nextEl": "#main-slider__swiper-button-next",
          "prevEl": "#main-slider__swiper-button-prev"
          },
          "autoplay": {
              "delay": 8000
          } 
      }'
    >
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div
            className="main-slider__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/slider-1-1.jpg)"
            }}
          />
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
          </div>
          <div className="main-slider__shape-2">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
          </div>
          <div className="main-slider__shape-3">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
          </div>
          <div className="main-slider__shape-4 float-bob-x">
            <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-slider__content">
                  <div className="main-slider__sub-title-box">
                    <p className="main-slider__sub-title">Innovation at Work</p>
                  </div>
                  <h2 className="main-slider__title">
                    Chauffeur Service Dubai
                  </h2>
                  <p
                    className="main-slider__text"
                    style={{ color: "goldenrod" }}
                  >
                    Best chauffeur service in Dubai <br /> We offer the most
                    affordable rates for chauffeur service in Dubai.
                  </p>
                  <div className="main-slider__btn-and-video">
                    <div className="main-slider__btn-box">
                      <a href="/about" className="main-slider__btn thm-btn">
                        Read More
                        <span className="icon-arrow-left" />
                      </a>
                    </div>
                    <div className="main-slider__video-link">
                      <a
                        href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                        className="video-popup"
                      >
                        <div className="main-slider__video-icon">
                          <span className="icon-play" />
                          <i className="ripple" />
                        </div>
                      </a>
                      <h4 className="main-slider__video-text">
                        See How It Works
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div
            className="main-slider__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/slider-1-2.jpg)"
            }}
          />
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
          </div>
          <div className="main-slider__shape-2">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
          </div>
          <div className="main-slider__shape-3">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
          </div>
          <div className="main-slider__shape-4 float-bob-x">
            <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-slider__content">
                  <div className="main-slider__sub-title-box">
                    <p className="main-slider__sub-title">Innovation at Work</p>
                  </div>
                  <h2 className="main-slider__title">
                    Luxury Limousine Service{" "}
                  </h2>
                  <p
                    className="main-slider__text"
                    style={{ color: "goldenrod" }}
                  >
                    Limousine Dubai Offers
                  </p>
                  <div className="main-slider__btn-and-video">
                    <div className="main-slider__btn-box">
                      <a href="/about" className="main-slider__btn thm-btn">
                        Read More
                        <span className="icon-arrow-left" />
                      </a>
                    </div>
                    <div className="main-slider__video-link">
                      <a
                        href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                        className="video-popup"
                      >
                        <div className="main-slider__video-icon">
                          <span className="icon-play" />
                          <i className="ripple" />
                        </div>
                      </a>
                      <h4 className="main-slider__video-text">
                        See How It Works
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div
            className="main-slider__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/slider-1-3.jpg)"
            }}
          />
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
          </div>
          <div className="main-slider__shape-2">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
          </div>
          <div className="main-slider__shape-3">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
          </div>
          <div className="main-slider__shape-4 float-bob-x">
            <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-slider__content">
                  <div className="main-slider__sub-title-box">
                    <p className="main-slider__sub-title">Innovation at Work</p>
                  </div>
                  <h2 className="main-slider__title">Limo Ride in Dubai </h2>
                  <p
                    className="main-slider__text"
                    style={{ color: "goldenrod" }}
                  >
                    Dubai Limo
                    <br /> Luxury Bus Rental Dubai
                  </p>
                  <div className="main-slider__btn-and-video">
                    <div className="main-slider__btn-box">
                      <a href="/about" className="main-slider__btn thm-btn">
                        Read More
                        <span className="icon-arrow-left" />
                      </a>
                    </div>
                    <div className="main-slider__video-link">
                      <a
                        href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                        className="video-popup"
                      >
                        <div className="main-slider__video-icon">
                          <span className="icon-play" />
                          <i className="ripple" />
                        </div>
                      </a>
                      <h4 className="main-slider__video-text">
                        See How It Works
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* If we need navigation buttons */}
      <div className="main-slider__nav">
        <div
          className="swiper-button-prev"
          id="main-slider__swiper-button-next"
        >
          <i className="icon-arrow-right" />
        </div>
        <div
          className="swiper-button-next"
          id="main-slider__swiper-button-prev"
        >
          <i className="icon-arrow-left" />
        </div>
      </div>
    </div>
  </section>
  {/*Service One Start */}
  <section className="service-one">
    <div className="container">
      <div className="row">
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft"
          data-wow-delay="100ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              {/*<span class="icon-car"></span>*/}
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/airporttransfer">Airport transfer</Link>
            </h3>
            <p className="service-one__text">
              When you are travelling to somewhere, it will be thrilling and
              exciting to enjoy your at its best and to reduce stress.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/airporttransfer">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay="200ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/busrental">
                Bus | Minibus | Van rental
              </Link>
            </h3>
            <p className="service-one__text">
              Experience the epitome of luxury while exploring the vibrant city
              of Dubai with our exclusive luxury bus rental services.
            </p>
            <div className="service-one__read-more">
              <Link to="/busrental">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="300ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/chauffeurservices">Chauffeur Service</Link>
            </h3>
            <p className="service-one__text">
              The politeness and dedication along with the smart driving skills
              of the experienced chauffeurs ensure a safe and luxurious service.
            </p>
            <div className="service-one__read-more">
              <Link to="/chauffeurservices">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft"
          data-wow-delay="400ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/limousineservice">Limousine Service Dubai</Link>
            </h3>
            <p className="service-one__text">
              There you will have a comfortable journey and can join a
              discussion online or arrange it even inside the limousine.
            </p>
            <div className="service-one__read-more">
              <Link to="/limousineservice">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay="500ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/hourlyrateservice">Hourly Rate Service</Link>
            </h3>
            <p className="service-one__text">
              We provide a luxurious fleet of cars and vans that enable us to
              transport in the best way with professional chauffeurs.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/hourlyrateservice">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Ground Transportation</Link>
            </h3>
            <p className="service-one__text">
              Customer satisfaction is crucial for amohlodi business as it leads
              to customer loyalty loves positive word-of-mouth, repeat.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
      </div>
    </div>
  </section>
  {/*Service One End */}
  {/*About One Start */}
  <section className="about-one">
    <div className="about-one__shape-2 float-bob">
      <img src="assets/images/shapes/about-one-shape-2.png" alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-6">
          <div
            className="about-one__left wow slideInLeft"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          >
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="about-one__img-1">
                  <img src="assets/images/resources/about-1-1.jpg" alt="" />
                  <div className="about-one__shape-1 float-bob-y">
                    <img
                      src="assets/images/shapes/about-one-shape-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="about-one__img-2">
                  <img src="assets/images/resources/about-1-2.jpg" alt="" />
                  {/*<div class="about-one__award-box">
                                      <div class="about-one__award-icon">
                                          <span class="icon-award"></span>
                                      </div>
                                      <div class="about-one__count count-box">
                                          <h3 class="count-text" data-stop="20" data-speed="1500">00</h3>
                                          <span>+</span>
                                      </div>
                                      <p class="about-one__count-text">Winning award</p>
                                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="about-one__right">
            <div className="section-title text-left">
              <div className="section-title__tagline-box">
                <span className="section-title__tagline">ABOUT US</span>
              </div>
              <h2 className="section-title__title">
                Reliable best luxury fleet Professionals at Your Service
              </h2>
            </div>
            <p className="about-one__text">
              We offer a diverse fleet of luxury vehicles to meet your
              transportation needs. Whether you need a taxicab, minivan, SUV, or
              VIP limo, we have it all. Our vehicles are regularly maintained to
              ensure a safe and comfortable ride for our clients.
            </p>
            <div className="about-one__points-box">
              <ul className="about-one__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check" />
                  </div>
                  <p>Personalised Service</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check" />
                  </div>
                  <p>Team of Experts</p>
                </li>
              </ul>
              <ul className="about-one__points list-unstyled">
                <li>
                  <div className="icon">
                    <span className="icon-check" />
                  </div>
                  <p>Great Care</p>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-check" />
                  </div>
                  <p>Finest Quality At Low Cost</p>
                </li>
              </ul>
            </div>
            <div className="about-one__btn-box">
              <a href="/about" className="about-one__btn thm-btn">
                Read More
                <span className="icon-arrow-left" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*About One End */}
  {/*Services Two Start */}
  <section
    className="services-two"
    style={{ backgroundColor: "#fff", paddingTop: 40 }}
  >
    <div className="container" >
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
            Our Fleets
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <div className="pageContent">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}

                  src="assets/images/fleet/Rolls20Phantom-new.jpg"
                style={{ color:'transparent'}}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }} className="alGallery">
                  Rolls Royce Phantom
                </div>
                <div className="al_brandName" style={{color:'goldenrod', fontWeight:'bold'}}>Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a 
                   className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('rollsroycephantom')}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " onClick={() => btnBookRollsRoycePhantom('Rolls Royce Phantom', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/Rolls20Ghost-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }} >Rolls Royce Ghost</div>
                <div style={{color:'goldenrod', fontWeight:'bold'}} className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('rollsroyceghost',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('Rolls Royce Ghost', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/rolls20cullinan-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Rolls-Royce Cullinan</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}}className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('rollsroycecullinan',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('Rolls-Royce Cullinan', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/bentley-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Bentley Flying Spur </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BENTLEY</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('bentleyflyingspur',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('Bentley Flying Spur', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/mercedes20class-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div  style={{ padding: "5px 0px", color:'white' }}>Mercedes S Class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('mercedessclass',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('Mercedes S Class', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/bmw207-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>BMW 7 series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'
                  >max. 2 Baggage</span>
                </div>
                <p className='text-white'
                >Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('bmw7series',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('BMW 7 series', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/audi-a8-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Audi A8 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('audia8',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('Audi A8', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/Cadillac20Escalade-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Cadillac Escalade</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">Cadillac Escalade</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('cadillacescalade',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('Cadillac Escalade', '6 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/gmc20yukon-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>GMC Yukon</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">GMC</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    onClick={() => btnRollsRoycePhantom('gmcyukon',)}
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore "  onClick={() => btnBookRollsRoycePhantom('GMC Yukon', '6 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/mercedesVclass-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Mercedes V class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>7 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/HondaOdyssey-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }} className='text-white'>Honda Odyssey</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">HONDA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
          <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/ToyotaPrevia-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Previa</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/ToyotaGranvia-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Granvia</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 3 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/luxus-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Lexus ES 350</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">LEXUS</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/mercedesEclass-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Mercedes E Class</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/bmw5-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>BMW 5 Series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets"  style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/AudiA6-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Audi A6</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/mercedessprinter-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Mercedes Sprinter</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 12 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/toyotahiace-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Hiace</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>11 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 7 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/toyotacoaster-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Coaster </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>21 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 16 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/3520luxurybus-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>35 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>35 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 30 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/5020luxurybus-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>50 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'
                  >50 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'
                  >max. 45 Baggage</span>
                </div>
                <p className='text-white'
                >Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/Tesla203-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Tesla Model 3 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'
                  >max. 2 Baggage</span>
                </div>
                <p className='text-white'
                >Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/Tesla20Y-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Tesla Model Y</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 3 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/limo-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Strech Limousine </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 0 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Services Two End */}
  {/*Contact One Start */}
  <section className="contact-one" style={{display:'none'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-5">
          <div className="contact-one__left">
            <h3 className="contact-one__title">
              Transform Your RIZ with <br />
              Our Your Expertise
            </h3>
            <div
              className="contact-one__img wow slideInLeft"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <img src="assets/images/resources/contact-one-img-1.jpg" alt="" />
              <div className="contact-one__video-link">
                <a
                  href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                  className="video-popup"
                >
                  <div className="contact-one__video-icon">
                    <span className="icon-play" />
                    <i className="ripple" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div
            className="contact-one__right wow slideInRight"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          >
            <h3 className="contact-one__right-title">Request A Quote </h3>
            <p className="contact-one__right-text">
              Dictum ultrices porttitor amet nec sollicitudin mi molestie
              adipiscing netus. Lorem at ac ut morbi ullamcorper molestie lacus.
              Euismod nulla viverra condimentum
            </p>
            <form
              action="assets/inc/sendemail.php"
              className="contact-one__form contact-form-validated"
              noValidate="novalidate"
            >
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <p className="contact-one__input-title">Your Email</p>
                  <div className="contact-one__input-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-paper-plan" />
                    </div>
                    <input type="email" placeholder="Your Email" name="email" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <p className="contact-one__input-title">Your Phone</p>
                  <div className="contact-one__input-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-call" />
                    </div>
                    <input type="text" placeholder="Your Phone" name="email" />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <p className="contact-one__input-title">Your Address</p>
                  <div className="contact-one__input-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-location" />
                    </div>
                    <input
                      type="text"
                      placeholder="Your Address"
                      name="email"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <p className="contact-one__input-title">Message</p>
                  <div className="contact-one__input-box text-message-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-envolop" />
                    </div>
                    <textarea
                      name="message"
                      placeholder="Write Message.."
                      defaultValue={""}
                    />
                  </div>
                  <div className="contact-one__btn-box">
                    <button type="submit" className="thm-btn contact-one__btn">
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="result" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Contact One End */}
  {/*Testimonial One Start*/}
  <section className="testimonial-one">
    <div className="container">
      <div className="testimonial-one__top">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="section-title text-left">
              <div className="section-title__tagline-box">
                <span className="section-title__tagline">
                  Reviews &amp; Testimonials
                </span>
              </div>
              {/*<h2 class="section-title__title">Affordable Roofing Solutions <br>for Every Budget</h2>*/}
            </div>
          </div>
          {/*<div class="col-xl-5 col-lg-6">
                      <div class="testimonial-one__top-text">
                          <p>Dictum ultrices porttitor amet nec sollicitudin mi molestie adipiscing netus. Lorem
                              at ac ut morbi ullamcorper molestie lacus</p>
                      </div>
                  </div>*/}
        </div>
      </div>
      <div className="testimonial-one__bottom">
        <div className="row">
          <div className="col-xl-4 col-lg-5">
            <div
              className="testimonial-one__img-box wow slideInLeft"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <div className="testimonial-one__img">
                <img
                  src="assets/images/testimonial/testimonial-one-img-1.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7">
            <div className="testimonial-one__bottom-right">
              <div className="testimonial-one__nav">
                <div className="swiper-button-next1">
                  <i className="icon-arrow-right" />
                </div>
                <div className="swiper-button-prev1">
                  <i className="icon-arrow-left" />
                </div>
              </div>
              <div
                className="thm-swiper__slider swiper-container"
                data-swiper-options='{
                              "slidesPerView": 1, 
                              "spaceBetween": 0,
                              "speed": 2000,
                              "loop": true,
                              "pagination": {
                                  "el": ".swiper-dot-style1",
                                  "type": "bullets",
                                  "clickable": true
                              },
      
                              
      
                              "navigation": {
                                  "nextEl": ".swiper-button-prev1",
                                  "prevEl": ".swiper-button-next1"
                              },
                              "autoplay": { "delay": 9000 },
                              "breakpoints": {
                                  "0": {
                                      "spaceBetween": 0,
                                      "slidesPerView": 1
                                  },
                                  "375": {
                                      "spaceBetween": 0,
                                      "slidesPerView": 1
                                  },
                                  "575": {
                                      "spaceBetween": 0,
                                      "slidesPerView": 1
                                  },
                                  "768": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  },
                                  "992": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  },
                                  "1200": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  },
                                  "1320": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  }
                              }
                          }'
              >
                <div className="swiper-wrapper">
                  {/*Testimonial One Single Start*/}
                  <div className="swiper-slide">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__quote">
                        <span className="icon-quote" />
                      </div>
                      <div className="testimonial-one__ratting">
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star last-icon" />
                      </div>
                      <p className="testimonial-one__text">
                        Amazing experience by the Riz Chauffeur Transportation
                        team. Special thanks goes to Mr. Rosary Lavin for
                        scheduling and organizing our trip from our villa in Al
                        Ain to Dubai Airport. We had initially opted for the
                        economy class vegicle. Mr. Rosary also, generously,
                        offered a larger vehicle as a company courtesy as we had
                        a large number of luggage. A BIG thank you for that.
                        Another special thanks goes to our Chauffeur.
                      </p>
                      <div className="testimonial-one__client-info">
                        <div className="testimonial-one__client-img">
                          <img
                            src="assets/images/testimonial/testimonial-one-client-img-1.png"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-one__client-content">
                          <h3>
                            <a href="testimonials.html">Michael Ramirez</a>
                          </h3>
                          <p>Marketing Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Testimonial One Single End*/}
                  {/*Testimonial One Single Start*/}
                  <div className="swiper-slide">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__quote">
                        <span className="icon-quote" />
                      </div>
                      <div className="testimonial-one__ratting">
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star last-icon" />
                      </div>
                      <p className="testimonial-one__text">
                        I recently had the pleasure of using Riz Chauffeurs for
                        my transportation needs in Dubai, and I couldn't be more
                        impressed. From the very beginning, they exceeded my
                        expectations.
                      </p>
                      <div className="testimonial-one__client-info">
                        <div className="testimonial-one__client-img">
                          <img
                            src="assets/images/testimonial/testimonial-one-client-img-2.png"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-one__client-content">
                          <h3>
                            <a href="testimonials.html">Robert Son</a>
                          </h3>
                          <p>Marketing Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Testimonial One Single End*/}
                  {/*Testimonial One Single Start*/}
                  <div className="swiper-slide">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__quote">
                        <span className="icon-quote" />
                      </div>
                      <div className="testimonial-one__ratting">
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star last-icon" />
                      </div>
                      <p className="testimonial-one__text">
                        We always use Riz chauffeur service for our offical
                        requirements and they give excellent service and have
                        always assisted us on short notices too. We have hired
                        all car categories and they have amazing fleet and
                        service.
                      </p>
                      <div className="testimonial-one__client-info">
                        <div className="testimonial-one__client-img">
                          <img
                            src="assets/images/testimonial/testimonial-one-client-img-3.png"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-one__client-content">
                          <h3>
                            <a href="testimonials.html">David Coper</a>
                          </h3>
                          <p>Marketing Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Testimonial One Single End*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Testimonial One End*/}
  {/*Blog One Start*/}
  <section className="blog-one">
    <div className="container">
      <div className="section-title text-center">
        <div className="section-title__tagline-box">
          <span className="section-title__tagline">Blogs</span>
        </div>
        {/*<h2 class="section-title__title">Toyota HiAce Chauffeur<br> Hire Van Hire Dubai </h2>*/}
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="100ms">
          <div className="blog-one__single">
            <div className="blog-one__img-box">
              <div className="blog-one__img">
                <img
                  src="assets/images/news/blog-1-1.jpg"
                  alt="@@title"
                  width={630}
                  height={320}
                />
                <img src="assets/images/news/blog-1-1.jpg" alt="@@title" />
                <a href="blog-details.html" className="blog-one__link">
                  <span className="sr-only" />
                </a>
              </div>
              <div className="blog-one__date">
                <p>
                  16<span>Mar</span>
                </p>
              </div>
            </div>
            <div className="blog-one__content">
              <ul className="blog-one__meta list-unstyled">
                <li>
                  <a href="blog-details.html">
                    <span className="icon-comment" />
                    Comments (05)
                  </a>
                </li>
                <li>
                  <a href="blog-details.html">
                    <span
                      className="icon-user
                                      "
                    />
                    By admin
                  </a>
                </li>
              </ul>
              <h3 className="blog-one__title">
                <a href="blog-details.html">
                  Toyota HiAce Chauffeur Hire Van Hire Dubai{" "}
                </a>
              </h3>
              <p className="blog-one__text">
                Toyota HiAce chauffeur-driven car hire is available in Dubai and
                throughout the UAE from Chauffeur Car Hire Dubai, delivering
                world-class chauffeur services for both business and leisure
                travel.
              </p>
              <div className="blog-one__read-more">
                <a href="blog-details.html">
                  Read More
                  <span className="icon-right-arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="200ms">
          <div className="blog-one__single">
            <div className="blog-one__img-box">
              <div className="blog-one__img">
                <img
                  src="assets/images/news/blog-1-2.jpg"
                  alt="@@title"
                  width={630}
                  height={320}
                />
                <img src="assets/images/news/blog-1-2.jpg" alt="@@title" />
                <a href="blog-details.html" className="blog-one__link">
                  <span className="sr-only" />
                </a>
              </div>
              <div className="blog-one__date">
                <p>
                  16<span>Mar</span>
                </p>
              </div>
            </div>
            <div className="blog-one__content">
              <ul className="blog-one__meta list-unstyled">
                <li>
                  <a href="blog-details.html">
                    <span className="icon-comment" />
                    Comments (05)
                  </a>
                </li>
                <li>
                  <a href="blog-details.html">
                    <span
                      className="icon-user
                                      "
                    />
                    By admin
                  </a>
                </li>
              </ul>
              <h3 className="blog-one__title">
                <a href="blog-details.html">Limousine Service in Dubai </a>
              </h3>
              <p className="blog-one__text">
                Experience the epitome of luxury by hiring one of our exquisite
                limousine cars with a professional chauffeur. Sit back, relax,
                and enjoy the journey like a true VIP{" "}
              </p>
              <div className="blog-one__read-more">
                <a href="blog-details.html">
                  Read More
                  <span className="icon-right-arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Blog One End*/}
  <section
    className="services-two"
    style={{ backgroundColor: "#fff", paddingTop: 50 }}
  >
    <div className="container" >
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
           Brands
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <br />
      <div className="slick-slider slick-initialized">
  <div className="slick-list">
    <div
      className="slick-track"
      style={{
        opacity: 1,
        transform: "translate3d(0px, 0px, 0px)",
        width: 1380
      }}
    >
      <div
        data-index={0}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
            <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-rangerover-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      
      <div
        data-index={2}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-rollsroyce-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={3}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-Lexus-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={4}
        className="slick-slide slick-active slick-current"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-gmc-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={5}
        className="slick-slide slick-active"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-bmw-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={1}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-audi-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    <br/>
<br/>
  </section>

  <section
    className="services-two"
    style={{ backgroundColor: "#fff", paddingTop: 50 }}
  >
    <div className="container" >
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
           Our Customers
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <br />
      <div className="slick-slider slick-initialized">
  <div className="slick-list">
    <div
      className="slick-track"
      style={{
        opacity: 1,
        transform: "translate3d(0px, 0px, 0px)",
        width: 1380
      }}
    >
      <div
        data-index={0}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
            <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/wallpaperflare4.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={1}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/wallpaperflare5.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={2}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/wallpaperflare6.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={3}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/wallpaperflare7.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={4}
        className="slick-slide slick-active slick-current"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/wallpaperflare8.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={5}
        className="slick-slide slick-active"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/wallpaperflare9.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    <br/>
<br/>
  </section>

  <>
  {/* /.page-wrapper */}
  <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
</>


  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>



    );
}
export default Index;